

<style>

.fixed {
	position: fixed;
	bottom: 0;
	width: 100%;
	text-align: center;
	padding: 10px;
	border-top: 1px solid #ccc;
	z-index: 100;
	background-color: #fff;
}
</style>

<template>
	<div >
		<Layout :fixHeader="true">
			<CompNav slot="header">header</CompNav>
			<router-view></router-view>
		</Layout>
		<footer class="fixed">
			<a href="https://beian.miit.gov.cn/#/Integrated/index">Copyright ©2019 ,www.tobog.cn,All Rights Reserved. | 皖ICP备19017849号-1</a>
		</footer>
	</div>
</template>

<script>
import CompNav from './components/nav'
export default {
	name: 'app',
	components: {
		CompNav,
	}
}
</script>
