<style lang="scss">
.layout-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d1d1d1;
    background-color: #fff;
    font-size: 16px;
    height: 100%;
    .logo {
        line-height: 1;
        min-width: 200px;
        img {
            height: 60px;
        }
    }

    .ui-menuitem-drop-icon {
        top: 0;
    }
    .menuitem {
        line-height: 42px;
        display: flex;
    }
}
</style>

<template>
	<header class="layout-header">
		<Menu v-bind="{mode:'horizontal',theme:'light'}">
			<a href="/" class="logo">
				<img src="../assets/logo.png" alt />
			</a>
			<!-- <MenuItem>
				<div slot="title">Vue</div>
				<MenuItem class="menuitem">
					<div slot="title">
						<a href="http://www.tobog.cn/ui-tobog/index.html#/guide">ui-tobog</a>
					</div>
				</MenuItem>
				<MenuItem class="menuitem">
					<div slot="title">
						<a href="http://www.tobog.cn/vue-tobog/doc/index.html">vue-tobog</a>
					</div>
				</MenuItem>
			</MenuItem>
			<MenuItem>
				<div slot="title">Flutter&Dart</div>
				<MenuItem class="menuitem">
					<div slot="title">
						<a href="http://www.tobog.cn/ui-tobog/index.html">flutter-demo</a>
					</div>
				</MenuItem>
			</MenuItem>
			<MenuItem>
				<div slot="title">Nginx</div>
			</MenuItem>
			<MenuItem>
				<div slot="title">Web</div>
				<MenuItem class="menuitem">
					<div slot="title">
						<a href="http://www.tobog.cn/pwa/doc.html">PWA</a>
					</div>
				</MenuItem>
			</MenuItem>
			<MenuItem>
				<div slot="title">Node</div>
			</MenuItem>
			<MenuItem>
				<div slot="title">Plugin</div>
			</MenuItem> -->
		</Menu>
	</header>
</template>




<script>
export default {

}
</script>