import Vue from 'vue'
import UiTobog from 'ui-tobog/dist/ui-tobog.umd.min.js'
import 'ui-tobog/dist/ui-tobog.css';
import App from './App.vue'
import routes from './routes/index'


Vue.use(UiTobog)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router:routes,
}).$mount('#app')
