
import router from 'vue-router'
import Vue from 'vue'

Vue.use(router);
export default new router({
    mode: 'hash',
    routes: [{
        path: "/",
        component: () => import('../components/main.vue'),
    }]
})